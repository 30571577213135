/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/assets/sass/tailwind.scss";
import "./src/assets/sass/base.scss";


import React from 'react';
import Layout from './src/components/layout';
import LiveChatButton from './src/components/livechat-button';
import Header from "./src/components/header"
import Footer from "./src/components/footer"
import CookiePopup from './src/components/cookie-popup';

export const wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      <Header />
      {element}
      <Footer />
      <LiveChatButton />
      <CookiePopup />
    </Layout>
  )
}



const scrollTo = (id) => () => {
  const el = document.querySelector(id);
  if (el) {
    return window.scrollTo(0, el.getBoundingClientRect().top - 81);
  }
  return false;
}

export const shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
  getSavedScrollPosition
}) => {
  const { location } = routerProps;
  const  { pathname, hash } = location;

  // is previous route from guides page?
  if (prevRouterProps.location.pathname.slice(1).split('/')[0] === 'guides') {
    const split = pathname.slice(1).split('/');

    if (!hash.length && (split[0] === 'guides' && split.length < 3)) {
      return false
    }
  }

  if (location.action === "PUSH") {
    window.scrollTo(0, 0);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.scrollTo(...(savedPosition || [0, 0]));
  }
}



export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    scrollTo(hash);
  }
}
import React from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"
import logo from '../images/greenbridge-logo-white.svg';

export default () => {

  const { wordpressAcfOptions, supportNav, companyNav, legalNav, guides } = useStaticQuery(graphql`
    {

      wordpressAcfOptions {
        options {
          global {
            formatted_address
          }
        }
      }

      supportNav: wordpressWpApiMenusMenusItems(name: {eq: "Footer - Support"}) {
        id
        name
        items {
          title
          order
          wordpress_parent
          classes
          description
          object
          object_id
          object_slug
          type_label
        }
      }

      companyNav: wordpressWpApiMenusMenusItems(name: {eq: "Footer - Company"}) {
        id
        name
        items {
          title
          order
          wordpress_parent
          classes
          description
          object
          object_id
          object_slug
          type_label
        }
      }

      legalNav: wordpressWpApiMenusMenusItems(name: {eq: "Footer - Legal"}) {
        id
        name
        items {
          title
          order
          wordpress_parent
          classes
          description
          object
          object_id
          object_slug
          type_label
        }
      }

      guides: allWordpressWpGuides(filter: {parent_element: {id: {eq: null}}}) {
        edges {
          node {
            id
            title
            slug
          }
        }
      }
    }
  `);

  return (
    <footer className="bg-black pt-16 pb-10 sm:py-20 text-white">
      <div className="o-wrapper">

        <div className="flex flex-wrap flex-col sm:flex-row justify-between">

          <div className="flex-auto">
            <Link to="/" className="relative">
              <img src={logo} alt="Greenbridge Logo" />
            </Link>
            <div className="mt-6 font-medium" dangerouslySetInnerHTML={{ __html: wordpressAcfOptions.options.global.formatted_address }}></div>
          </div>

          {/* Navs */}
          <div className="grid w-full md:w-auto sm:grid-cols-3 grid-cols-2 grid-rows-2 gap-6 justify-between sm:justify-end mt-12">

            <nav className="flex flex-col md:items-end">
              <div className="inline-block">
                <h4 className="text-lg font-semibold sm:mb-4 mb-3">Help and support</h4>
                {supportNav.items.map(ni =>
                  <div key={`footer-nav-support-${ni.object_id}`}>
                    <Link to={ni.object_slug.includes('live-chat') ? '#live-chat' : '/'+ni.object_slug } className="inline-block text-15px sm:mb-2 mb-1 hover:opacity-75">
                      {ni.title}
                    </Link>
                  </div>
                )}
              </div>
            </nav>

            <nav className="flex flex-col md:items-end row-span-2">
              <div className="inline-block">
                <h4 className="text-lg font-semibold sm:mb-4 mb-3">Guides</h4>
                {guides.edges.map(({node}, i)=>
                  <div key={`footerguide-${node.id}`}><Link to={`/guides#${node.slug}`} className="inline-block text-15px  sm:mb-2 mb-1 hover:opacity-75" href="https://google.com">{node.title}</Link></div>
                )}
              </div>
            </nav>

            <nav className="flex flex-col md:items-end">
              <div className="inline-block">
                <h4 className="text-lg font-semibold sm:mb-4 mb-3">Company</h4>
                {companyNav.items.map(ni =>
                  <div key={`footer-nav-company-${ni.object_id}`}>
                    <Link to={'/'+ni.object_slug} className="inline-block text-15px  sm:mb-2 mb-1 hover:opacity-75">
                      {ni.title}
                    </Link>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </div>

        <div className="footer__legal text-center flex flex-col sm:flex-row flex-wrap justify-between text-15px mt-16 pt-6 border-t border-grey">

          <div className="my-2 order-1 sm:order-0">&copy; GreenbridgeTechnology</div>

          <nav className="flex -ml-6 opacity-75 my-2 justify-center">
            {legalNav.items.map(ni =>
              <div className="pl-6" key={`footer-nav-legal-${ni.object_id}`}>
                <Link
                  to={'/' + ni.object_slug}
                  className="inline-block sm:mb-2 mb-1 hover:opacity-75"
                  dangerouslySetInnerHTML={{ __html: ni.title }}
                />
              </div>
            )}
          </nav>
        </div>

      </div>
    </footer>
  );

}
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from 'styled-components';
import 'hamburgers/_sass/hamburgers/hamburgers.scss';

import MobileNav from './mobile-nav';
import Logo from '../images/greenbridge-logo.svg';

const Header = styled.header`
  transition: box-shadow .2s ease-in-out;
  &.is-scrolled {
    transition: box-shadow .3s ease-in-out;
    box-shadow:0 5px 7px 0 rgba(0,0,0,0.04);
  }
  @media screen and (min-width:768px) { /* sm breakpoint */
    box-shadow:none;
  }
`

const OffsetLogo = styled.div`
  bottom:4px;
`

export default () => {

  const { header, actions } = useStaticQuery(graphql`
  {
    header: wordpressWpApiMenusMenusItems(name: {eq: "Header"}) {
      id
      name
      items {
        title
        order
        url
        wordpress_parent
        classes
        description
        object
        object_id
        object_slug
        type_label
      }
    }

    actions: wordpressWpApiMenusMenusItems(name: {eq: "Header Actions"}) {
      id
      name
      items {
        title
        order
        url
        target
        wordpress_parent
        classes
        description
        object
        object_id
        object_slug
        type_label
      }
    }
  }
  `)

  const { items } = header;
  const [ mobileNavOpen, setMobileNavOpen ] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const node = React.useRef();
  const handleScroll = () => setScrolled(window.scrollY >= 30);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {passive: true});
    return () => window.removeEventListener("scroll", handleScroll,{passive: true});
  });

  return (
    <>
      {/* <HeaderHeight /> */}
      <Header className={`sticky left-0 top-0 right-0 bg-white z-50 header lg:py-3 py-4 ${scrolled ? 'is-scrolled' : ''}`}>
        <div className="o-wrapper flex items-center justify-between relative">
          {/* Left side */}
          <div className="flex items-center justify-between lg:justify-start flex-auto ">

            <OffsetLogo className="relative">
              <Link to="/">
                <img src={Logo} style={{width:'100%',maxWidth:'145px', minWidth: '145px',}} alt="Greenbridge Logo" />
              </Link>
            </OffsetLogo>

            <nav className="hidden sm:block xl:ml-10 lg:ml-4 text-16px">
              {items.map(ni =>
                <Link
                  to={'/' + ni.object_slug}
                  className="inline-block font-semibold hover:opacity-75 lg:ml-10 ml-6"
                  activeClassName="text-green"
                  partiallyActive={true}
                  key={`header-nav-${ni.object_id}`}
                >
                  {ni.title}
                </Link>
              )}
            </nav>
          </div>

          {/* Right side */}
          <div className="hidden lg:flex items-center text-16px">
            {actions.items.map((action, i) =>
              <a
                key={`header-action-item-${action.object_id}`}
                href={action.url}
                target={action.target}
                className={`c-btn ${i > 0 ? ' bg-black text-white hover:bg-green-light hover:text-black ml-2 ' : ' hover:opacity-75 '}`}
              >
                {action.title}
              </a>
            )}
          </div>

          <div className="sm:hidden" ref={node}>
            <Burger open={mobileNavOpen} setOpen={setMobileNavOpen} />
            <MobileNav
              open={mobileNavOpen}
              setOpen={setMobileNavOpen}
              actions={actions}
            />
          </div>

        </div>
      </Header>

    </>
  )
}



// const StyledBurger = styled.button`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
//   width: 2rem;
//   height: ${({ open }) => open ? '2rem' : '1.5rem'};
//   background: transparent;
//   border: none;
//   cursor: pointer;
//   padding: 0;
//   z-index: 10;

//   &:focus {
//     outline: none;
//   }

//   div {
//     width: 2rem;
//     height: 0.2rem;
//     background: #000000;
//     border-radius: 10px;
//     transition: all 0.3s linear;
//     position: relative;
//     transform-origin: 1px;

//     :first-child {
//       transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
//     }

//     :nth-child(2) {
//       opacity: ${({ open }) => open ? '0' : '1'};
//       transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
//     }

//     :nth-child(3) {
//       transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
//     }
//   }
// `

const StyledBurger = styled.button`
padding:0;
display:flex;
transform:scale(.9);
transform-origin:right center;
&,
& * {outline:none!important;}
`
const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger onClick={() => setOpen(!open)} className={`hamburger hamburger--spin ${open ? 'is-active' : ''}`} type="button">
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </StyledBurger>

  )
}

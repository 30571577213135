import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from 'styled-components';
// import Arrow from '../images/circle-arrow.svg';

const MobileNavEl = styled.section`
.mobile-nav {
  transform: translateX(-100%);
  /* transition: opacity .4s; */
  pointer-events:none;
  height: calc(100vh - 68px);
  top:68px;
  left:0;
  bottom:auto;
  right:0;
  position:fixed;
  z-index: 40;
  opacity: 0;

  &.is-open {
    transform: none;
    opacity: 1;
    pointer-events:all;
  }

  a.text-blue {
    .mobile-nav-arrow {
      circle {
        stroke:#3EB9C2;
      }
      path {
        stroke:#3EB9C2;
      }
    }
  }

  &::-webkit-scrollbar,
  & > div::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none !important;
  }
}
`


export default function MobileNav(props){
  const { open, actions, setOpen } = props;

  const { nav } = useStaticQuery(graphql`
  {
    nav: wordpressWpApiMenusMenusItems(name: {eq: "Mobile Nav"}) {
      id
      name
      items {
        title
        order
        wordpress_parent
        classes
        description
        object
        object_id
        object_slug
        type_label
      }
    }
  }
  `)

  return (
    <MobileNavEl>
      <div className={`sm:hidden mobile-nav bg-white overflow-hidden flex flex-col justify-between overflow-y-auto ${open ? 'is-open' : ''}`}>
        <div className="o-wrapper w-full">
          <nav className="py-10">
            {nav.items.map(ni =>
                <Link
                  to={'/' + ni.object_slug}
                  onClick={() => setOpen(false)}
                  className="text-h4 flex items-center justify-between font-semibold hover:opacity-75 mb-5 leading-snug"
                  activeClassName="text-green"
                  partiallyActive={true}
                  key={`mobile-nav-${ni.object_id}`}
                >
                  <div>
                    {ni.title}
                  </div>
                  <div>
                    <svg className="mobile-nav-arrow transform rotate-90 scale-90" width="31" height="31" xmlns="http://www.w3.org/2000/svg"><g transform="rotate(90 15.5 15.5)" stroke="#000" strokeWidth="2.1" fill="none" fillRule="evenodd"><circle cx="15.5" cy="15.5" r="14.45"/><path d="M17 20l-4-4 4-4"/></g></svg>
                  </div>
                </Link>
            )}
          </nav>
        </div>

        {/* Actions */}
        <div className="o-wrapper bg-black text-white w-full flex-auto">
          <div className="py-10">

            <div className="-mb-3">
              {actions.items.map((action, i) =>
                <a href={action.url} target={action.target} className="w-full block c-btn bg-white text-black hover:bg-green-light hover:text-black mb-3" key={`header-action-item-${action.object_id}`}>
                  {action.title}
                </a>
              )}
            </div>

            <div className="pt-6 text-center">&copy; GreenbridgeTechnology</div>

          </div>
        </div>
      </div>
    </MobileNavEl>
  )
}

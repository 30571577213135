import React, { useEffect, useState } from 'react';
import LiveChat from 'react-livechat';
import styled from 'styled-components';
// import UpArrow from '../images/up-arrow.svg';

const LivechatButtonElement = styled.div`
  transition:transform .2s ease;
  box-shadow: 0 10px 79px 0 rgba(0,0,0,0.04);
  z-index: 2147483640;

  @media screen and (min-width:640px) {
    bottom:25px;
    right:25px;
  }

  &.livechat-button--hidden,
  .livechat-button--hidden {
    transform:translateY(calc(100% + 25px));
  }


  .c-btn {
    min-width:0;
    font-size:14px!important;
    padding: 11px 26px;
    @include mq($until:sm) {
      padding: 9px 20px;
    }
  }


  &.small-button {
    transition:all .2s;
    &.is-hidden {
      transform:translateY(calc(100% + 25px));
      transition:none;
    }

    .up-arrow {
      path {
        transition:fill .2s;
      }
    }

    &:hover {
      .up-arrow {
        path {
          fill:#000;
        }
      }
    }
  }

`


export default function LivechatButton() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [chatOpen, setChatOpen] = useState(false);
    const [boxHidden, setBoxHidden] = useState(false);

    useEffect(() => {
        bindLiveChatLinks();
    });

    function openChatWindow() {
        window.LC_API.open_chat_window();
    }

    function hideBox() {
        setBoxHidden(true);
    }

    return (
        <>

            <LivechatButtonElement
                className={`${!boxHidden || chatOpen ? 'is-hidden' : ''} small-button fixed left-0 sm:left-auto bottom-0 right-0 bg-black hover:bg-green-light text-white hover:text-black cursor-pointer overflow-hidden text-center flex justify-center items-center py-2 px-6 font-semibold`}
                onClick={() => { setBoxHidden(false) }}
                style={{ bottom: 0 }}
            >
                <div className="mr-6 sm:mr-8 md:mr-12 lg:mr-16 xl:mr-20">Need some help?</div>
                <svg className="up-arrow" xmlns="http://www.w3.org/2000/svg" style={{ width: '14px' }} viewBox="0 0 492 492"><path d="M442.627 185.388L265.083 7.844C260.019 2.78 253.263 0 245.915 0c-7.204 0-13.956 2.78-19.02 7.844L49.347 185.388c-10.488 10.492-10.488 27.568 0 38.052l16.12 16.128c5.064 5.06 11.82 7.844 19.028 7.844 7.204 0 14.192-2.784 19.252-7.844l103.808-103.584v329.084c0 14.832 11.616 26.932 26.448 26.932h22.8c14.832 0 27.624-12.1 27.624-26.932V134.816l104.396 104.752c5.06 5.06 11.636 7.844 18.844 7.844s13.864-2.784 18.932-7.844l16.072-16.128c10.492-10.488 10.452-27.56-.044-38.052z" fill="#fff" /></svg>
            </LivechatButtonElement>


            <LivechatButtonElement className={`${!isLoaded || (isLoaded && chatOpen) || (isLoaded && boxHidden) ? 'livechat-button--hidden' : ''} livechat-button fixed left-0 sm:left-auto bottom-0 right-0 bg-white text-black overflow-hidden`}>
                <div>
                    <svg
                        onClick={() => hideBox()}
                        className="livechat-close relative ml-auto mr-0 -mb-1"
                        style={{ bottom: '1px' }}
                        width="34" height="35" xmlns="http://www.w3.org/2000/svg"
                    >
                        <g fill="none" fillRule="evenodd"><path className="bgg" fill="#000" d="M0 .5h34v34H0z" /><path d="M22.207 12.293a1 1 0 0 0-1.414 0L17.5 15.586l-3.293-3.293a1 1 0 0 0-1.414 1.414L16.086 17l-3.293 3.293a1 1 0 1 0 1.414 1.414l3.293-3.293 3.293 3.293a1 1 0 0 0 1.414-1.414L18.914 17l3.293-3.293a1 1 0 0 0 0-1.414z" fill="#FFF" fillRule="nonzero" /></g>
                    </svg>

                    <div className="sm:p-6 p-4 pt-0 sm:pt-0">
                        <h3 className="font-bold leading-snug" style={{ marginBottom: '.15rem' }}>Need some help?</h3>
                        <p className="text-14px">We're available on Live Chat {/*and WhatsApp */}</p>
                        <div className="grid grid-cols-2 gap-2 mt-4">
                            <button onClick={openChatWindow} className="c-btn bg-black text-white hover:text-black hover:bg-green-light">Talk on Live Chat</button>
                            {/*<a href="https://wa.me/447999148328" target="_blank" rel="noopener noreferrer" className="c-btn c-btn--outline c-btn--outline--no-hover border flex items-center justify-center hover:opacity-75">*/}
                            {/*  <img src={require('../images/whatsapp.svg')} alt="WhatsApp Logo" className="mr-1 relative" />*/}
                            {/*  WhatsApp*/}
                            {/*</a>*/}
                        </div>
                    </div>
                </div>


                <LiveChat
                    license={11364017}
                    onChatLoaded={(ref) => {
                        ref.on_after_load = function () {
                            if (window.LC_API && window.LC_API.hide_chat_window) {
                                window.LC_API.hide_chat_window();
                            }

                            bindLiveChatLinks();
                            setIsLoaded(true);

                            setTimeout(() => {
                                if (window.LC_API && window.LC_API.hide_chat_window) {
                                    window.LC_API.hide_chat_window();
                                }
                                document.querySelector('#chat-widget-container').classList.add('is-shown');
                            }, 500);
                        }

                        ref.on_chat_window_opened = () => {
                            setChatOpen(true);
                        }

                        ref.on_chat_window_minimized = () => {
                            window.LC_API.hide_chat_window();
                            setChatOpen(false);
                        }
                    }}

                />


            </LivechatButtonElement>
        </>
    );
}


function bindLiveChatLinks() {
    if (typeof document === 'undefined') return;

    [...document.querySelectorAll('a')]
        .filter(link => link.href === '#live-chat' || link.innerText.toLowerCase().includes('live chat') || link.innerText.toLowerCase().includes('chat with us'))
        .forEach(a => {
            a.addEventListener('click', e => {
                e.preventDefault();
                window.LC_API.show_full_view();
                return false;
            })
        });
}
import React, { Component } from 'react'
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';

const Container = styled.div`
  .cookieConsent {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    background-color: #000;
    color:#fff;

    max-width: calc(100% - 450px);
    padding:.6rem;
    display:flex;
    align-items:center;
    box-shadow:0 0 10px 10px rgba(0,0,0,.04);

    > :first-child {
      line-height:1.45;
      flex: 1 1 auto;
      margin-right:1.5rem;
      font-size:16px;
    }

    button {
      flex: 0 1 auto;
      padding:11px 26px;
      font-size:14px;
      min-width:0;

    }

    @media screen and (max-width:831px) {
      flex-direction:column;
      z-index:2147483641;


      > :first-child {
        margin-right:auto;
        margin-left:auto;
        margin-bottom:.5rem;
        max-width:250px;
        text-align:center;

      }
    }

    @media screen and (max-width:640px) {
      width:100%;
      max-width:100%;
      flex-direction:row;
      > :first-child {
        max-width:100%;
        margin-bottom:0;
        text-align:left;
        font-size:14px;
      }
      button {
        padding:7px 23px;
      }
    }
  }
`;

export default class CookiePopup extends Component {


  render() {
    return (
      <Container>
        <CookieConsent
          location="bottom"
          buttonText="Accept Cookies"
          buttonClasses={'c-btn bg-white text-black hover:bg-green-light'}
          acceptOnScroll={true}
          disableStyles
          // debug={true}
          cookieName="cookieconsent"
          expires={150}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </Container>
    )
  }
}
